import clsx from 'clsx';

import { NavLink, useLocation } from 'react-router-dom';

export default function Footer({...props}) {
    const page = useLocation();

    return (
        <div className={clsx((page === "radar" || page === "map") ? "border-t border-lwbg3" : "mt-4", "text-[13px] lg:text-md px-4 xl:px-0 tracking-[0.2px] font-medium pb-3")}>
            <div className={clsx((page === "radar" || page === "map") && "pt-2.5", page !== "radar" && page !== "map" && page !== "cameras" ? "border-t border-lwbg3 px-4 pt-2.5 max-w-[72rem]" : "max-w-[70rem]", "mx-auto text-sm text-center lg:text-justify space-y-2 lg:space-y-0 lg:flex items-center justify-between")}>
                <div className="flex items-center justify-center space-x-1.5">
                    <NavLink to="/">
                        Copyright &copy; 2022 - {new Date().getFullYear()} LetsTikTok.
                    </NavLink>
                    <NavLink to="/" className="hidden sm:block">
                        All rights reserved.
                    </NavLink>
                </div>
                <div className="hidden lg:flex items-center space-x-2 justify-center md:justify-between">
                    {/*<a href="/discord">*/}
                    {/*    <Discord alt="Discord" className="text-gray-500 dark:text-gray-400 w-[16px] h-[16px]" />*/}
                    {/*</a>*/}
                </div>
            </div>
        </div>
    )
}