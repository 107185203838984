import { useHash } from '../utils/LocationUtil';
import Tag from './Tag';

export default function Home() {
    const hash = useHash();

    if(hash)
        return <Tag tag={hash} />

    return (
        <div>
            <h1>Home</h1>
        </div>
    )
}