import { useFetch } from '../utils/FetchUtil';
import { useEffect, useState } from 'react';
import {
    CommentsCount,
    Likes,
    Loading,
    Shares,
    Verified,
    Views
} from '../components/ui/Icons';
import { NavLink } from 'react-router-dom';

import 'react-h5-audio-player/lib/styles.css';

import Comments from '../components/comment/Comments';
import clsx from 'clsx';
import H5AudioPlayer from 'react-h5-audio-player';
import Moment from '../utils/TimeUtil';

export default function Video({video}) {
    const videoFetch = useFetch();

    const [loading, setLoading] = useState(true);
    const [imageIndex, setImageIndex] = useState(0);

    useEffect(() => {
        videoFetch.fetchNow(`https://api.letstiktok.com/v1/video/${video}`);
    }, [video]);

    const v = videoFetch?.data;
    const valid = v?.code !== 404;

    const isVideo = v?.video?.images === null;

    return videoFetch.loading ? <Loading className="pt-2"/> : (
        <div>
            {valid ? (
                <div className="2xl:flex 2xl:space-x-5">
                    <div className="px-4 xl:px-0 flex-shrink-0">
                        {isVideo ? (
                            <video className="border border-lwbg3 rounded-md mx-auto" src={v?.video?.view?.noWatermark} onLoadedData={() => setLoading(false)} controls style={{maxHeight: "calc(100vh - 100px)"}}/>
                        ) : (
                            <div>
                                <img className="border border-lwbg3 rounded-md mx-auto max-w-full md:max-w-[600px] object-contain " src={v?.video?.images[imageIndex].url} onLoad={() => setLoading(false)} style={{maxHeight: "calc(100vh - 100px)"}}/>
                                <div className="flex justify-center items-center mt-2 text-center text-sm space-x-2">
                                    {v?.video?.images.map((image, index) => (
                                        <button key={index} onClick={() => setImageIndex(index)} className={clsx("px-2.5 py-1 rounded-md", imageIndex === index ? "bg-lwbg3" : "bg-lwbg2")}>{index + 1}</button>
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="px-4 md:px-6 xl:px-8 2xl:px-0 py-2 md:w-full">
                        <div className="block md:hidden w-fit border border-lwbg3 rounded-md px-4 py-2 bg-lwbg2/30 mt-1.5 mb-5 mx-auto">
                            <div className="flex items-center space-x-3">
                                <Likes amount={v?.stats.likes}/>
                                <Views amount={v?.stats.views}/>
                                <CommentsCount amount={v?.stats.comments}/>
                                <Shares amount={v?.stats.shares}/>
                            </div>
                        </div>
                        <div className="flex justify-center md:justify-between space-x-3">
                            <img src={v?.author.avatar.larger} alt="Avatar" className="h-20 w-20 rounded-xl flex-shrink-0"/>
                            <div className="text-2xl flex-1">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <NavLink to={`/@${v?.author.uniqueId}`} className="flex font-bold items-center">
                                            {v?.author.username}{v?.author.verified && <Verified className="ml-1 mb-0.5 w-5 h-5" color="#11A1F2"/>}
                                        </NavLink>
                                        <div className="-mt-2.5 text-xs font-semibold text-gray-300">@{v?.author.uniqueId}</div>

                                        <div className="-mt-2.5 text-xs font-semibold text-gray-300"><Moment unix={v?.video.created} time={true}/></div>
                                    </div>
                                    <div className="hidden md:block -mt-2 w-fit border border-lwbg3 rounded-md px-4 py-2 bg-lwbg2/30">
                                        <div className="flex items-center space-x-3">
                                            <Likes amount={v?.stats.likes}/>
                                            <Views amount={v?.stats.views}/>
                                            <CommentsCount amount={v?.stats.comments}/>
                                            <Shares amount={v?.stats.shares}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="font-medium text-sm whitespace-pre-wrap">{v?.video.desc}</div>
                            </div>
                        </div>
                        <div className="mt-5 pt-2.5 border border-lwbg3 rounded-md md:px-4 py-2 bg-lwbg2/30">
                            <div className="flex items-center space-x-1 pl-4">
                                <img src={v?.video.music.cover.default} alt="Cover" className="h-10 w-10 rounded-xl flex-shrink-0"/>
                                <div className="pl-2">
                                    <div className="flex text-lg font-bold items-center">
                                        {v?.video.music.title}
                                    </div>
                                    <div className=" text-xs font-semibold text-gray-300">by @{v?.video.music.author}</div>
                                </div>
                            </div>
                            <H5AudioPlayer controls preload="metadata"  showSkipControls={false} src={v?.video.music.play}/>
                        </div>
                        {/*<div className="border-t border-lwbg3 mt-5">*/}
                        {/*    <Comments comments={v?.video?.comments}/>*/}
                        {/*</div>*/}
                    </div>
                </div>
            ) : (
                <div className="text-center text-gray-500">
                    <div className="text-center pt-2 text-lg font-bold text-red-500">Video Not Found!</div>
                </div>
            )}
        </div>
    );
}