import { useRoutes } from 'react-router-dom';

import Home from './pages/Home';
import Discover from './pages/Discover';
import Trending from './pages/Trending';
import IdPage from './components/pages/IdPage';

export function LetsTikTokRoutes() {
    return useRoutes([
        { path: "/", element: <Home /> },
        { path: "/discover", element: <Discover /> },
        { path: "/trending", element: <Trending /> },


        { path: "/:id", element: <IdPage /> },
        { path: "/:id/:id2", element: <IdPage /> },
        { path: "/:id/video/:id2", element: <IdPage /> },
    ]);
}