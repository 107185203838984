import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { fetchJson } from './FetchUtil';

export function isJson(str) {
    if(str === "null" || str === null || str === undefined)
        return false;

    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }

    return true;
}

export function useHash(includePound = false) {
    let offset = useLocation().hash;
    if(offset !== "") offset = offset.substring(1);

    return includePound ? (offset === "" ? "" : `#${offset}`) : offset;
}

export function useDebug() {
    let [ searchParams ] = useSearchParams();

    return searchParams.get("debug") !== null;
}
