import { NavLink, useLocation } from 'react-router-dom';

import Logo from '../../assets/images/Logo';
// import Search from '../components/interactive/Search';

import clsx from 'clsx';
import Search from '../interactive/Search';

const Pages = [
    {
        name: 'Trending'
    },
    {
        name: 'Discover'
    }
]

export default function Header() {
    const loc = useLocation();

    return (
        <div className="sticky top-0 md:bg-lwbg" style={{zIndex: "500"}}>
            <div className={clsx("relative z-100 h-0 my-0 mx-auto", loc.pathname === "/" ? "max-w-[1260px]" : "max-w-[1360px]")}>
                <Logo />
            </div>
            <div className={clsx(!loc.pathname.startsWith("/en/") && !loc.pathname !== "/map" && !loc.pathname !== "/radar" && "pb-3")}>
                <header className={clsx("h-[62px] mb-[-62px] border-b border-lwbg3 mx-auto hidden md:flex flex-row justify-center items-center p-[22px] mx-auto relative z-10 duration-300 transition-opacity bg-lwbg", loc.pathname === "/" ? "max-w-[1260px]" : "max-w-[1360px]")}>
                    <div className="text-[13px] font-medium tracking-[0.2px] flex items-center gap-[32px]">
                        {Pages.map((page, i) => (
                            <div key={i} className={page.new !== undefined && page.new ? "flex items-center gap-[6px]" : ""}>
                                <NavLink to={`/${page.name.toLowerCase()}`} className={({isActive}) => !isActive ?
                                    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300" : "text-white"
                                }>
                                    {page.name}
                                </NavLink>
                                {page.new !== undefined && page.new && (
                                    <span
                                        className="relative inline-flex items-center py-[1px] px-[6px] bg-none rounded-[10px] gradient text-[#2bafff] text-[10px] tracking-[0.2px] bf-gradient">New</span>
                                )}
                            </div>
                        ))}
                    </div>
                    <div
                        className="absolute right-[24px] flex flex-row items-center justify-end gap-[18px] text-[13px] font-medium tracking-[0.2px] duration-200 transition-all transition">
                        <Search small={true} />
                        {/*<NavLink to="/log-in" className={({isActive}) => !isActive ?*/}
                        {/*    "text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 hidden xl:flex" : "text-white hidden xl:flex"*/}
                        {/*}>Log In</NavLink>*/}
                        {/*<Button name="Sign Up" func="/sign-up"*/}
                        {/*        className="hidden xl:flex text-white opacity-[60%] hover:opacity-100 transition-opacity duration-300 tracking-[0.2px]"/>*/}
                    </div>
                </header>
            </div>
        </div>
    )
}