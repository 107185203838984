import { useFetch } from '../utils/FetchUtil';
import { useEffect } from 'react';

import { Followers, Following, Likes, Link, Loading, Verified, Videos } from '../components/ui/Icons';

import VideosPreview from '../components/video/VideosPreview';
import ReactCountryFlag from 'react-country-flag';

export default function User({user}) {
    const userFetch = useFetch();

    useEffect(() => {
        userFetch.fetchNow(`https://api.letstiktok.com/v1/user/${user.toLowerCase()}`);
    }, [user]);

    const u = userFetch?.data;
    const valid = u?.code !== 404;

    return userFetch.loading ? <Loading className="pt-2"/> : (
        <div>
            {valid ? (
                <div className="pt-2 px-4 xl:px-0">
                    <div className="md:flex justify-between items-center">
                        <div className="flex justify-center md:justify-between items-center space-x-3">
                            <img src={u?.avatar.larger} alt="Avatar" className="h-20 w-20 rounded-xl"/>
                            <div className="text-2xl">
                                <div className="flex font-bold items-center">
                                    {u?.username}{u?.verified && <Verified className="ml-1 mb-0.5 w-5 h-5" color="#11A1F2"/>}
                                </div>
                                <div className="-mt-2.5 text-xs font-semibold text-gray-300">@{u?.uniqueId}</div>
                                <div className="font-medium text-sm whitespace-pre-wrap">{u?.signature}</div>
                            </div>
                        </div>
                        <div className="flex justify-center mt-4 md:mt-0">
                            <div className="w-fit border border-lwbg3 rounded-md px-6 py-3 bg-lwbg2/30">
                                <div className="flex items-center space-x-5">
                                    <Videos amount={u?.stats.videos}/>
                                    <Likes amount={u?.stats.likes}/>
                                    <Followers amount={u?.stats.followers}/>
                                    <Following amount={u?.stats.following}/>
                                </div>
                                {(u?.links?.url || u?.region) && (
                                    <div className="flex items-center justify-center pt-1.5 space-x-1 text-xs-md md:text-sm">
                                        {u?.region && <div><ReactCountryFlag
                                                                        svg
                                                                        cdnUrl="https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.4.3/flags/1x1/"
                                                                        cdnSuffix="svg"
                                                                        title="US"
                                                                        style={{
                                                                            width: '1.2em',
                                                                            height: '0.6em',
                                                                            borderRadius: '0.1em',
                                                                            marginBottom: "1.5px"
                                                                        }}
                                                                        countryCode={u?.region} /> {u?.region}</div>}
                                        {u?.region && u?.links?.url && <div className="font-semibold">⋅</div>}
                                        {u?.links?.url && <Link url={u?.links?.url?.toLowerCase()}/>}
                                    </div>
                                )}
                            </div>
                        </div>

                    </div>
                    <div className="relative flex pt-3.5 pb-5 items-center -mx-[2.25rem]">
                        <div className="flex-grow border-t border-lwbg3"></div>
                        <span className="flex-shrink mx-4 font-medium text-sm text-gray-300 tracking-wider">VIDEOS</span>
                        <div className="flex-grow border-t border-lwbg3"></div>
                    </div>
                    <div className="px-3.5">
                        <VideosPreview videos={u?.videos} hasMore={u?.hasMore || true}/>
                    </div>
                </div>
            ) : (
                <div className="text-center pt-2 text-lg font-bold text-red-500">User Not Found!</div>
            )}
        </div>
    )
}