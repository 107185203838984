import { useEffect, useState } from 'react';

export const CURRENT_IP = '192.168.68.62';

export function fetchJson(url, success, fail = undefined, method = 'POST', log = false, port = 8000) {
    fetch(`${url.includes("://") ? url : ((process.env.NODE_ENV !== `production` ? `http://${CURRENT_IP}:${port}` : ``) + url)}`, {
        method: method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
    })
        .then(response => response.json())
        .then(json => success(json))
        .catch(e => {
            if (e === undefined)
                return;
            if(log)
                console.error(e);

            if(fail !== undefined)
                fail(e)
        });
}

export function useFetchAsync(url?: string, options?: any, callback?: any, error?: any) {
    if(url.endsWith("false"))
        return;

    fetch(`${process.env.NODE_ENV !== `production` ? `http://${CURRENT_IP}:8000` : ``}${url}`, options)
        .then((res: any) => res.json())
        .then((res: any) => {
           callback(res);
        })
        .catch((e: Error) => {
            error(e);
        });
}

export function useFetch(url?: string, options?: any) {
    const [status, setStatus] = useState({ loading: false });
    const [num, setNum] = useState(1);

    function empty() {
        setStatus({ loading: false, data: {}, error: undefined });
    }

    function fetchNow(url: string, options?: any, addType = false) {
        if(process.env.NODE_ENV !== `production`)
            console.log(url, options);

        if(url === undefined)
            return;

        const n = num;

        setStatus({ loading: true });
        fetch(`${url}`, options)
            .then((res: any) => res.json())
            .then((res: any) => {
                if(n !== num)
                    return;

                setNum(n + 1);

                if(res.status !== undefined && res.error !== undefined) {
                    setStatus({ loading: false, error: res.error });
                } else {
                    setStatus({ loading: false, data: addType ? {...res, type: url.split("/")[2]} : res, error: res.status !== 200 && res.code !== undefined && res.code !== 200 ? res : undefined });
                }
            })
            .catch((error: Error) => {
                if(n !== num)
                    return;

                setNum(n + 1);
                setStatus({ loading: false, error });
            });
    }

    useEffect(() => {
        if (url) {
            fetchNow(url, options);
        }
    }, []);

    return { ...status, empty, fetchNow, setStatus };
}