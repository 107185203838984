import { useEffect, useState } from 'react';

import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useFetch, useFetchAsync } from '../../utils/FetchUtil';

import Loader from 'react-loader-spinner';
import clsx from 'clsx';

import hashtag from '../../assets/images/hashtag.jpg';

export default function Search({small, mobile}) {
    const page = useLocation();
    const navigate = useNavigate();
    const loc = useLocation();

    const [search, setSearch] = useState('');
    const [typing, setTyping] = useState(false);
    const [focusing, setFocusing] = useState(false);
    const [typingTimeout, setTypingTimeout] = useState(0);
    const [results, setResults] = useState([]);
    const [fetch, setFetch] = useState(false);

    const fetchUsers = useFetch();
    const fetchVideos = useFetch();
    const fetchTags = useFetch();

    useEffect(() => {
        if(fetch) {
            setFetch(false);
            if(!search.startsWith("#")) {
                fetchUsers.fetchNow(`https://api.letstiktok.com/v1/user/${search.toLowerCase().replace("@", "")}`);
            } else {
                fetchUsers.empty();
            }
            if(!search.startsWith("@")) {
                fetchVideos.fetchNow(`https://api.letstiktok.com/v1/video/${search}`);
            } else {
                fetchVideos.empty();
            }
            if(!search.startsWith("@")) {
                fetchTags.fetchNow(`https://api.letstiktok.com/v1/tag/${search.replace("#", "")}`);
            } else {
                fetchTags.empty();
            }
        }
    }, [fetch]);

    useEffect(() => {
        if(search !== "" && fetchUsers.data !== undefined && fetchVideos.data !== undefined && fetchTags.data !== undefined && !fetchUsers.loading && !fetchVideos.loading && !fetchTags.loading)
            setResults(getResults());
    }, [fetchTags.data, fetchUsers.data, fetchVideos.data]);

    useEffect(() => {
        setFocusing(false);
        setSearch('');
    }, [loc.pathname])

    function onChange(e) {
        if (typingTimeout)
            clearTimeout(typingTimeout);

        setSearch(e.target.value);
        setTyping(true);
        setTypingTimeout(setTimeout(() => {
            if(e.target.value.split(' ').join('') !== "") {
                setResults([]);
                setTyping(false);
                setFetch(true);
            }
        }, 800));
    }

    function getResults() {
        const results = [];

        if(fetchUsers.data !== undefined && Object.keys(fetchUsers.data).length > 0 && fetchUsers.data.code === undefined)
            results.push({type: 'user', ...fetchUsers.data});
        if(fetchVideos.data !== undefined && Object.keys(fetchVideos.data).length > 0 && fetchVideos.data.code === undefined)
            results.push({type: 'video', ...fetchVideos.data});
        if(fetchTags.data !== undefined && Object.keys(fetchTags.data).length > 0 && fetchTags.data.code === undefined)
            results.push({type: 'tag', ...fetchTags.data});

        console.log(results);

        return results;
    }

    function focusEnter(e) {
        if (e.currentTarget === e.target) {
            setFocusing(true);
        }
    }

    function focusLeave(e) {
        if (e.currentTarget === e.target && e.relatedTarget === null)
            setFocusing(false);

        if(e.relatedTarget !== null && e.relatedTarget.id === "list") {
            setTimeout(() => {
                setFocusing(false);
                setSearch('');
            }, 200);
        }
    }

    function onKeyDown(e) {
        if (e.keyCode === 13) {
            const text = e.target.value;

            if (text.startsWith("@")) {
                navigate(`/${text}`);
            }
        }
    }

    const show = search !== "" && !typing && results.length > 0;
    const isHome = small === undefined;
    const isMobile = mobile !== undefined;
    const max = isHome ? 8 : 12;

    return (
        <div tabIndex="0" className="w-full outline-none">
            <div className={clsx("flex bg-gray-800/10 shadow-sm", isHome ? 'mx-auto h-12 p-2 max-w-3xl' : 'h-8 p-1 max-w-[14.5rem]', focusing ? 'rounded-t-lg border border-lwbg3 border-b-transparent' : 'rounded-lg border border-lwbg3')}>
                <div className={clsx("text-gray-700", isHome ? "pt-[5px] ml-2 " : "pt-1 ml-1.5")}>
                    <svg xmlns="http://www.w3.org/2000/svg" className={clsx(isHome ? "w-5 h-5" : "w-4 h-4")} viewBox="0 0 16 16">
                        <path fill="currentColor" d="M15.784 13.68l-4.096-4.096c.585-.955.94-2.067.94-3.27a6.315 6.315 0 1 0-6.314 6.314 6.253 6.253 0 0 0 3.27-.937l4.096 4.094a.751.751 0 0 0 1.052 0l1.052-1.052a.75.75 0 0 0 0-1.053zm-9.47-3.157a4.21 4.21 0 1 1 0-8.419 4.21 4.21 0 0 1 0 8.42z"/>
                    </svg>
                </div>
                <input onKeyDown={e => onKeyDown(e)} onFocus={focusEnter.bind(this)} onBlur={focusLeave.bind(this)} value={search} onChange={onChange} placeholder="Search" className={clsx("w-full bg-transparent mx-3 dark:text-white font-medium focus:outline-none", isHome ? "text-lg" : "text-sm")}/>
                {(typing || (fetchUsers.loading || fetchVideos.loading || fetchTags.loading)) && (
                    <div className="flex flex-center mr-1.5">
                        <Loader type="Oval" color="#00BFFF" className={isHome ? "mt-1" : "mt-0.5"} height={isHome ? 25 : 20} width={isHome ? 30 : 15} />
                    </div>
                )}
            </div>
            {focusing && (
                <div className={clsx('absolute transform w-full z-50', isMobile ? 'max-w-[14.5rem]' : (isHome ? 'min-w-3xl max-w-3xl left-1/2 -translate-x-1/2 px-4 md:px-0' : 'min-w-[14.5rem] max-w-[14.5rem] px-2 sm:px-0'))} id="list">
                    <div className="rounded-b-lg shadow-lg text-gray-800 truncate bg-[#0C0F15] border border-t-transparent border-lwbg3">
                        {show ? (
                            <ul>
                                {results.slice(0, max).map((r, i) => (
                                    <li key={i}>
                                        <NavLink id="list" to={r.type === "tag" ? `/#${r.title}` : `/@${r.uniqueId}`}>
                                            <div className={clsx("transition-colors duration-200 hover:bg-gray-800/20 text-gray-300", isHome ? "p-3" : "p-2 text-sm")}>
                                                <div className="pl-1">
                                                    <div className="group flex items-center space-x-1">
                                                        <div>
                                                            <img src={r.type === "tag" ? r.cover === null ? hashtag : r.cover.default : r.avatar.default} className="h-5 w-5 rounded-md" alt="avatar"/>
                                                        </div>
                                                        <div>{r.type === "user" && "@"}{r.type === "tag" && "#"}{r.type === "tag" ? r.title : r.uniqueId}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        ) : (
                            <ul>
                                {/*{!isHome && locations.filter(loc => loc !== undefined).slice(0, max).map((l, i) => (*/}
                                {/*    <li key={i}>*/}
                                {/*        <LocationPreview search={true} loc={l} showAlerts={true}/>*/}
                                {/*    </li>*/}
                                {/*))}*/}
                            </ul>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}