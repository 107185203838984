import { useParams } from 'react-router-dom';

import User from '../../pages/User';
import Video from '../../pages/Video';

export default function IdPage() {
    const user = useParams().id;
    const id = useParams().id2;

    if(user.startsWith("@") && id === undefined) {
        return <User user={user.substring(1)}/>
    } else if(id !== undefined) {
        return <Video video={id}/>
    } else {
        return null;
    }
}