import React from 'react';

import RMoment from 'react-moment';
import moment from 'moment';

export default class Moment extends React.Component {

    render() {
        const unix = this.props.unix;
        const time = this.props.time === undefined;
        const fromNow = time && new Date().getTime() - unix < 3600000;
        const tod = this.props.tod;

        if(this.props.date !== undefined) {
            return (
                <span title={moment.unix(unix / 1000).format("MMM DD, YYYY")}>
                <RMoment interval={30000} className="span" format="MMM DD, YYYY" unix>
                    {unix / 1000}
                </RMoment>
            </span>
            )
        }

        if(tod !== undefined && tod) {
            const greetings = generateGreetings(unix);

            return (
                <span title={moment.unix(unix / 1000).format(`dddd [${greetings}]`)}>
                    <RMoment className="span" format={`dddd [${greetings}]`} unix>
                        {unix / 1000}
                    </RMoment>
                </span>
            )
        }

        if(this.props.timeDay !== undefined)
            return (
                <span title={moment.unix(unix / 1000).format("MMMM DD, YYYY [at] LT")}>
                    <RMoment interval={30000} className="span" fromNow={false} calendar={{sameDay: 'LT', nextDay: 'LT', nextWeek: 'LT', lastDay: 'LT', lastWeek: 'LT', sameElse: 'LT'}} unix>
                        {unix / 1000}
                    </RMoment>
                </span>
            )

        return (
            <span title={moment.unix(unix / 1000).format("MMMM DD, YYYY [at] LT")}>
                <RMoment interval={30000} className="span" fromNow={this.props.date !== undefined ? false : fromNow} calendar={this.props.date !== undefined ? {} : !fromNow && time ? {} : {sameDay: !time ? 'LT' : '[Today at] LT', nextDay: '[Tomorrow at] LT', nextWeek: 'dddd', lastDay: '[Yesterday at] LT', lastWeek: 'MMM DD, YYYY [at] LT', sameElse: 'MMM DD, YYYY [at] LT'}} unix>
                    {unix / 1000}
                </RMoment>
            </span>
        )
    }
}

export function notEqualTOD(unix1, unix2) {
    const greetings1 = generateGreetings(unix1);
    const greetings2 = generateGreetings(unix2);

    return moment.unix(unix1 / 1000).format(`dddd [${greetings1}]`) !== moment.unix(unix2 / 1000).format(`dddd [${greetings2}]`);
}

function generateGreetings(unix) {
    const currentHour = moment.unix(unix / 1000).format("HH");

    if (currentHour >= 3 && currentHour < 12) {
        return "Morning";
    } else if (currentHour >= 12 && currentHour < 15) {
        return "Afternoon";
    }   else if (currentHour >= 15 && currentHour < 20) {
        return "Evening";
    } else if (currentHour >= 20 && currentHour < 3) {
        return "Night";
    } else {
        return "";
    }
}
