import { LetsTikTokRoutes } from './LetsTikTokRoutes';

import clsx from 'clsx';

import Header from './components/nav/Header';
import Footer from './components/nav/Footer';
import { useLocation } from 'react-router-dom';

export default function LetsTikTok() {
    const loc = useLocation();

    return (
        <div className={clsx("font-inter antialiased flex transition-colors duration-500 flex-col text-gray-200 bg-lwbg min-h-screen")}>
            <div className="flex-grow">
                <Header/>

                <div className={clsx(loc.pathname === "/" ? "max-w-[70rem]" : "max-w-[80rem]", "mx-auto pt-[62px]")}>
                    <LetsTikTokRoutes/>
                </div>
            </div>

            <Footer/>
        </div>
    );
}