import VideoPreview from './VideoPreview';

export default function VideosPreview({videos, hasMore = true}) {
    return (
        <div className="flex justify-center">
            {videos?.length > 0 && (
                <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-3">
                    {videos?.filter(v => v.video !== undefined).map((v, i) => <VideoPreview key={i} video={v}/>)}
                    {videos?.length > 23 && hasMore && (
                        <div className="flex items-center justify-center w-[241px] h-[437px] border border-lwbg3 rounded-md">
                            <div>
                                <button type="button" className="text-white bg-gradient-to-br from-[#DE4D0DFF] to-[#B23ACBFF] focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800 font-medium rounded-lg text-sm px-4 py-2 text-center mb-2 duration-500 transition hover:scale-[1.03]">Load More...</button>
                                <div className="text-center text-xs-md">Coming Soon</div>
                            </div>
                        </div>
                    )}
                </div>
            )}
            {videos?.length === 0 && (
                <div className="text-center text-sm font-medium text-red-500">User has no videos.</div>
            )}
        </div>
    )
}