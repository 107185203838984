import { Likes, Loading, Views } from '../ui/Icons';
import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import clsx from 'clsx';

export default function VideoPreview({video}) {
    const isVideo = video.video.images === null;

    const [loading, setLoading] = useState(true);
    const [showVideo, setShowVideo] = useState(false);
    const [showInfo, setShowInfo] = useState(true);

    return (
        <div className="w-[241px] h-[437px]" onMouseEnter={() => setShowInfo(false)} onMouseLeave={() => setShowInfo(true)}>
            {loading && (
                <div className="absolute w-[241px] h-[437px]">
                    <Loading className="pt-[200px]"/>
                </div>
            )}
            {loading === null && (
                <div className="w-[241px] h-[437px] border border-lwbg3 rounded-md">
                    <div className="pt-[200px]">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="red" className="mx-auto w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
                        </svg>
                    </div>
                    <div className="text-sm text-red-400 text-center pt-0.5">Failed to load.</div>
                </div>
            )}
            {loading !== null && isVideo ? showVideo ? <video src={video?.video.view.noWatermark} autoPlay controls className="object-cover rounded-md border border-lwbg3 w-[241px] h-[437px]"/> : <img onError={() => setLoading(null)} src={!showInfo ? video?.video.cover.dynamic : video?.video.cover.default} onClick={() => {
                if(loading === false)
                    setShowVideo(true);
            }} className={clsx(loading === false && "cursor-pointer", "object-cover rounded-md border border-lwbg3 w-[241px] h-[437px]")} onLoad={() => setLoading(false)}/> : null}
            {!isVideo && (
                <div className="w-[241px] h-[437px] border border-lwbg3 rounded-md">
                    <NavLink to={`/@${video.author.uniqueId}/video/${video.video.id}`}>
                        <img onError={() => setLoading(null)} src={video?.video?.images[0].url} className={clsx(loading === false && "cursor-pointer", "rounded-md border border-lwbg3 w-[241px] h-[437px]")} onLoad={() => setLoading(false)}/>
                    </NavLink>
                </div>
            )}
            <div className={clsx(!showInfo && showVideo && "hidden", "flex justify-between items-center relative bottom-[32px] transition duration-500 ease-in-out px-2 bg-lwbg/60 h-[32px] rounded-b-md border-l border-b border-r border-lwbg3")}>
                <Views amount={video?.stats?.views} preview={true}/>
                <NavLink to={`/@${video.author.uniqueId}/video/${video.video.id}`}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-3.5 h-3.5">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 003 8.25v10.5A2.25 2.25 0 005.25 21h10.5A2.25 2.25 0 0018 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
                    </svg>
                </NavLink>
            </div>
        </div>
    )
}